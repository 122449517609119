<template>
    <div class="menu">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-dark">
                <div class="container-fluid">
                    <button
                        class="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        id="navbar-toggler"
                    >
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div
                        class="collapse navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <a class="nav-link" href="#">Home</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#sobre">sobre</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#facetas">facetas</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#modelo"
                                    >PACIENTE MODELO</a
                                >
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#contato">contato</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#localizacao"
                                    >localização</a
                                >
                            </li>
                        </ul>
                        <div class="d-flex redes">
                            <a
                                target="_blank"
                                href="https://www.facebook.com/dramonicairma/"
                            >
                                <img
                                    src="@/assets/img/icone-facebook.png"
                                    class="d-block img-fluid mx-auto"
                            /></a>
                            <a
                                target="_blank"
                                href="https://www.instagram.com/dramonicairma/"
                            >
                                <img
                                    src="@/assets/img/icone-instagram.png"
                                    class="d-block img-fluid"
                                    style="padding-left: 20px"
                            /></a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        const fechar = document.getElementById("navbar-toggler");
        const elementos = document.getElementsByClassName("nav-link");

        for (var i = 0; i < elementos.length; ++i) {
            elementos[i].onclick = function () {
                if (window.screen.width <= 768) {
                    fechar.click();
                }
            };
        }
    },
};
</script>

<style src="./style.scss" lang="scss" scoped/>
