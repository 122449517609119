<template>
  <div class="footer text-center">
    <p class="m-0 py-2">©2021 Todos os direitos reservados.</p>
  </div>
</template>

<script>
export default {};
</script>

<style src="./style.scss" lang="scss" scoped/>