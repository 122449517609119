<template>
    <div class="home">
        <div
            id="carouselExampleIndicators"
            class="carousel slide"
            data-bs-ride="carousel"
        >
            <div class="carousel-inner d-none d-md-block">
                <div class="carousel-item active">
                    <img src="@/assets/img/home-1.png" class="d-block w-100" />
                </div>
                <div class="carousel-item">
                    <img src="@/assets/img/home-2.png" class="d-block w-100" />
                </div>
                <div class="carousel-item">
                    <img src="@/assets/img/home-3.png" class="d-block w-100" />
                </div>
                <div class="carousel-item">
                    <img src="@/assets/img/home-4.png" class="d-block w-100" />
                </div>
                <div class="carousel-item">
                    <img src="@/assets/img/home-5.png" class="d-block w-100" />
                </div>
                <div class="carousel-item">
                    <img src="@/assets/img/home-6.png" class="d-block w-100" />
                </div>
                <div class="carousel-item">
                    <img src="@/assets/img/home-7.png" class="d-block w-100" />
                </div>
            </div>
            <div class="carousel-inner d-md-none">
                <div class="carousel-item active">
                    <img
                        src="@/assets/img/home-1-bg.png"
                        class="d-block w-100"
                    />
                </div>
                <div class="carousel-item">
                    <img
                        src="@/assets/img/home-2-bg.png"
                        class="d-block w-100"
                    />
                </div>
                <div class="carousel-item">
                    <img
                        src="@/assets/img/home-3-bg.png"
                        class="d-block w-100"
                    />
                </div>
                <div class="carousel-item">
                    <img
                        src="@/assets/img/home-4-bg.png"
                        class="d-block w-100"
                    />
                </div>
                <div class="carousel-item">
                    <img
                        src="@/assets/img/home-5-bg.png"
                        class="d-block w-100"
                    />
                </div>
                <div class="carousel-item">
                    <img
                        src="@/assets/img/home-6-bg.png"
                        class="d-block w-100"
                    />
                </div>
                <div class="carousel-item">
                    <img
                        src="@/assets/img/home-7-bg.png"
                        class="d-block w-100"
                    />
                </div>
            </div>
        </div>
        <div class="logo animate__animated animate__fadeIn">
            <img
                src="@/assets/img/home-logo.png"
                class="img-fluid d-block mx-auto "
            />
            <!-- <a class="btn btn-padrao mx-auto d-table mt-5" href="#sobre"
                >Saiba mais</a
            > -->
        </div>
        <div class="frase">
            <h1 class="animate__animated animate__fadeInLeft">Conforto e excelência no atendimento</h1>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style src="./style.scss" lang="scss" scoped/>