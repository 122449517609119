<template>
    <div class="sobre" id="sobre">
        <div class="container">
            <div class="row">
                <div
                    class="
                        col-12 col-md-6
                        d-flex
                        flex-column
                        justify-content-center
                        wow
                        animate__animated animate__fadeInLeft
                    "
                >
                    <h2>Dra Mônica Irma Aparecida Valdeci de Souza</h2>
                    <h3>CRO/SP 108965</h3>
                    <p class="mt-4">
                        Dra. Mônica Irma é graduada em Odontologia pela UNESP -
                        campus de Araraquara. <br />
                        Possui título de mestra e doutora em Ciências
                        Odontológicas pela UNESP <br />
                        Atua com excelência em estética reabilitadora e
                        restauradora , além de ser docente em cursos presenciais
                        e online de Estética e Odontopediatria
                    </p>
                </div>
                <div
                    class="
                        col-12 col-md-6
                        wow
                        animate__animated animate__fadeInRight
                    "
                >
                    <!-- <img
                        src="@/assets/img/logo-sobre.png"
                        class="d-block img-fluid mx-auto"
                    /> -->
                          <video
                        class="
                            mx-auto
                            d-table
                            wow
                            animate__animated animate__fadeInRight
                        "
                        width="100%"
                       
                        controls
                    >
                        <source
                            src="@/assets/img/video-3.mp4"
                            type="video/mp4"
                        />
                    </video>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style src="./style.scss" lang="scss" scoped/>