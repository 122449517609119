<template>
  <div id="localizacao">
    <iframe
      src="https://maps.google.com/maps?q=RUA%20LICINIO%20AVELINO%20DA%20COSTA,%20119%20&t=&z=17&ie=UTF8&iwloc=&output=embed"
      width="100%"
      height="450"
      frameborder="0"
      style="border: 0; margin-bottom: -6px"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>