<template>
    <div>
        <div class="facetas" id="facetas">
            <div class="container">
                <div class="row">
                    <div class="col-12 mb-5 titulo text-center">
                        <h1>FACETAS DE RESINA COMPOSTA <br> REABILITAÇÃO MINIMAMENTE INVASIVA</h1>
                        
                    </div>
                    <div
                        class="
                            col-12 col-md-6
                            d-flex
                            flex-column
                            justify-content-center
                            wow
                            animate__animated animate__rotateInDownLeft
                        "
                    >
                        <p>
                            As facetas de resina é um dos procedimentos mais
                            buscados atualmente por quem deseja transformar o
                            sorriso sem desgaste ou com o mínimo de desgaste
                            dental possível e de forma rápida.
                            <br /><br />
                            Esse procedimento requer muita habilidade manual do
                            cirurgião dentista para que a estética seja
                            devolvida de forma natural e harmônica com o rosto
                            do paciente.
                            <br /><br />
                            Normalmente as facetas de resina são realizadas em
                            sessão única e em casos em que não há desgaste é
                            considerado um procedimento reversível e indolor.
                        </p>
                    </div>
                    <div
                        class="
                            col-12 col-md-6
                            wow
                            animate__animated animate__rotateInDownRight
                        "
                    >
                        <img
                            src="@/assets/img/faceta.png"
                            class="d-block img-fluid mx-auto"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-ex"></div>
    </div>
</template>

<script>
export default {};
</script>

<style src="./style.scss" lang="scss" scoped/>