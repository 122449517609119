<template>
    <div class="modelos" id="modelo">
        <div class="container">
            <div class="row">
                <div class="col-12 mb-5 titulo text-center">
                    <h1>
                        AS FACETAS DE RESINA COMPOSTA SÃO INDICIADAS PARA <br>
                        MELHORAR COR, FORMATO E POSIÇÃO DO DENTE
                    </h1>
                </div>
                <div
                    class="
                        col-12 col-md-5
                        d-flex
                        flex-column
                        justify-content-center
                        wow
                        animate__animated animate__fadeInLeft
                        px-5
                    "
                >
                    <VueSlickCarousel v-bind="settings">
                        <div>
                            <img
                                src="@/assets/img/12.jpg"
                                class="d-block img-fluid mx-auto"
                            />
                        </div>
                        <div>
                            <img
                                src="@/assets/img/2.jpg"
                                class="d-block img-fluid mx-auto"
                            />
                        </div>
                        <div>
                            <img
                                src="@/assets/img/3.jpg"
                                class="d-block img-fluid mx-auto"
                            />
                        </div>
                        <div>
                            <img
                                src="@/assets/img/4.jpg"
                                class="d-block img-fluid mx-auto"
                            />
                        </div>
                        <div>
                            <img
                                src="@/assets/img/5.jpg"
                                class="d-block img-fluid mx-auto"
                            />
                        </div>
                        <div>
                            <img
                                src="@/assets/img/6.jpg"
                                class="d-block img-fluid mx-auto"
                            />
                        </div>
                        <div>
                            <img
                                src="@/assets/img/7.jpg"
                                class="d-block img-fluid mx-auto"
                            />
                        </div>
                        <div>
                            <img
                                src="@/assets/img/8.jpg"
                                class="d-block img-fluid mx-auto"
                            />
                        </div>
                        <div>
                            <img
                                src="@/assets/img/9.jpg"
                                class="d-block img-fluid mx-auto"
                            />
                        </div>
                        <div>
                            <img
                                src="@/assets/img/10.jpg"
                                class="d-block img-fluid mx-auto"
                            />
                        </div>
                        <div>
                            <img
                                src="@/assets/img/11.jpg"
                                class="d-block img-fluid mx-auto"
                            />
                        </div>
                        <div>
                            <img
                                src="@/assets/img/1.jpg"
                                class="d-block img-fluid mx-auto"
                            />
                        </div>
                    </VueSlickCarousel>
                </div>
                <div class="col-12 borde col-md-6 offset-md-1">
                    <video
                        class="
                            mx-auto
                            d-table
                            wow
                            animate__animated animate__fadeInRight
                        "
                        width="85%"
                        height="450"
                        controls
                    >
                        <source
                            src="@/assets/img/video-2.mp4"
                            type="video/mp4"
                        />
                    </video>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
    name: "MyComponent",
    components: { VueSlickCarousel },
    data() {
        return {
            settings: {
                dots: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 8000,
                pauseOnDotsHover: true,
                pauseOnFocus: true,
                pauseOnHover: true,
            },
        };
    },
};
</script> 

<style src="./style.scss" lang="scss" scoped/>