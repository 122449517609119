<template>
    <div id="app">
        <Menu />
        <Home />
        <Sobre />
        <Facetas />

        <Modelos />
        <Contato />
        <Maps />
        <Footer />
    </div>
</template>

<script>
import Menu from "./components/menu/Menu.vue";
import Home from "./components/home/Home.vue";
import Sobre from "./components/sobre/Sobre.vue";

import Footer from "./components/footer/Footer.vue";
import Facetas from "./components/facetas/Facetas.vue";
import Maps from "./components/maps/Maps.vue";
import Contato from "./components/contato/Contato.vue";
import Modelos from "./components/modelos/Modelos.vue";
import WOW from "wowjs";
export default {
    name: "App",
    components: {
        Menu,
        Home,
        Sobre,

        Facetas,
        Footer,
        Maps,
        Contato,
        Modelos,
    },
    mounted() {
        new WOW.WOW().init();
    },
};
</script>

<style lang="scss">
</style>
