<template>
    <div class="contato" id="contato">
        <div class="container wow animate__animated animate__zoomIn">
            <div class="row">
                <div
                    class="
                        col-12
                        py-4
                        col-md-6
                        d-flex
                        flex-column
                        justify-content-center
                        borda
                        text-center
                    "
                >
                    <h2>Quem pode ser paciente modelo?</h2>
                    <p>
                        Seja meu paciente modelo
                        <br /><br />
                        Os pacientes modelos da Dra Mônica Irma tem condições
                        exclusivas e diferenciadas
                        <br />
                        Ficou interessado em ser meu paciente modelo?
                    </p>
                    <p>Entre em contato via WhatsApp:</p>
                    <a
                        class="btn btn-branco d-table mx-auto mt-3"
                        target="_blanck"
                        href="https://api.whatsapp.com/send?phone=5511955852297&text=Ol%C3%A1%2C%20gostaria%20de%20ser%20seu%20paciente%20modelo."
                        >WHATSAPP</a
                    >
                </div>
                <div
                    class="
                        col-12
                        py-4
                        col-md-6
                        d-flex
                        flex-column
                        justify-content-center
                        text-center
                    "
                >
                    <video class="px-3" width="100%" height="360" controls>
                        <source
                            src="@/assets/img/video-1.mp4"
                            type="video/mp4"
                        />
                    </video>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style src="./style.scss" lang="scss" scoped/>